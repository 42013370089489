import Link from "next/link"
import React from "react"
import { FormattedMessage } from "react-intl"
import { config } from "../../lib/config"
import { LanguageSwitch } from "../shared/language-swtich"
import { Image } from "../shared/image"

export const TalentBerlinHeader: React.FC = () => {
  return (
    <div className="header py-3 bg-white">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center">
          <div>
            <Link href="/jobs">
              <a>
                <Image
                  alt="talent-berlin"
                  src="/web-catalog-app/assets/talent-berlin/logo.svg"
                  width={180}
                  height={40}
                />
              </a>
            </Link>
          </div>
          <div>
            <LanguageSwitch
              size="sm"
              color="secondary"
              className="mr-1 text-uppercase text-light"
            />

            <a className="btn btn-primary btn-sm" href={config.urls.companyApp}>
              <span className="text-uppercase text-nowrap">
                <FormattedMessage
                  id="bestcareer.header.partnerLogin"
                  defaultMessage="Partner login"
                />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TalentBerlinHeader
